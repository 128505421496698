var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"changepasswordForm",staticClass:"d-flex flex-column fill-height justify-space-between",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',[(_vm.passwordExpires < 7 && _vm.passwordExpires > 0)?_c('div',{staticClass:"d-flex font-italic mb-2"},[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" mdi-exclamation")]),_vm._v(" "+_vm._s(_vm.$t('system.profile.notifications.pwExpires', { passwordExpires: _vm.passwordExpires, }))+" ")],1):_vm._e(),_c('ant-input',{staticStyle:{"margin-top":"0 !important"},attrs:{"is-optional":false,"label":`${_vm.$t('system.profile.currentPW')}`},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":_vm.currentPasswordFieldType === 'password'
              ? 'mdi-eye-outline'
              : 'mdi-eye-off-outline',"label":_vm.$t('system.profile.currentPW'),"placeholder":_vm.$t('system.profile.EnterCurrentPW'),"rules":[_vm.rules.required],"type":_vm.currentPasswordFieldType,"dense":"","filled":"","single-line":""},on:{"click:append":function($event){_vm.currentPasswordFieldType === 'password'
              ? (_vm.currentPasswordFieldType = 'text')
              : (_vm.currentPasswordFieldType = 'password')}},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}})]},proxy:true}])}),_c('ant-input',{attrs:{"is-optional":false,"label":`${_vm.$t('system.profile.newPW')}`},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":_vm.newPasswordFieldType === 'password'
              ? 'mdi-eye-outline'
              : 'mdi-eye-off-outline',"label":_vm.$t('system.profile.newPW'),"placeholder":_vm.$t('system.profile.enterNewPW'),"rules":[_vm.rules.required, _vm.rules.password, _vm.passwordUniqueRule],"type":_vm.newPasswordFieldType,"dense":"","filled":"","single-line":""},on:{"click:append":function($event){_vm.newPasswordFieldType === 'password'
              ? (_vm.newPasswordFieldType = 'text')
              : (_vm.newPasswordFieldType = 'password')}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]},proxy:true}])}),_c('ant-input',{staticStyle:{"margin-top":"0 !important"},attrs:{"is-optional":false,"label":`${_vm.$t('system.profile.repeatNewPW')}`},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":_vm.confirmPasswordFieldType === 'password'
              ? 'mdi-eye-outline'
              : 'mdi-eye-off-outline',"label":_vm.$t('system.profile.repeatNewPW'),"placeholder":_vm.$t('system.profile.enterNewPW'),"rules":[_vm.rules.required, _vm.passwordConfirmationRule],"type":_vm.confirmPasswordFieldType,"dense":"","filled":"","single-line":""},on:{"click:append":function($event){_vm.confirmPasswordFieldType === 'password'
              ? (_vm.confirmPasswordFieldType = 'text')
              : (_vm.confirmPasswordFieldType = 'password')}},model:{value:(_vm.confirmNewPassword),callback:function ($$v) {_vm.confirmNewPassword=$$v},expression:"confirmNewPassword"}})]},proxy:true}])})],1),_c('v-btn',{attrs:{"color":"primary","elevation":"0","type":"submit","width":"100%"}},[_vm._v(" "+_vm._s(_vm.$t('general.save'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }